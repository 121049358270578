import React from 'react'
import {isEmpty} from 'lodash'
import './StationInfo.css'
import logo from '../../assets/logo.png'

const StationInfo = (params) => {
  const {merchant='', warehouse=''} = params
  const savedAccount = localStorage.getItem('account')||''
  return (
    <div className='station-container'>
      <div className='logo-container'>
        <img src={logo} alt='小黄龙回收' className='logo-image' />
      </div>
      {
        isEmpty(warehouse)
        ?
      <div className='empty'>没有找到当前账号:{savedAccount}关联的站点，请联系管理员</div>
        :
        <div className='merchant-container'>
          <div className='merchant-label'>当前站点</div>
          <div className='merchant-name'>{merchant}</div>
          <div className='warehouse-name'>{warehouse}</div>
        </div>
      }
    </div>
  )
}

export default StationInfo
import 'babel-core/'
import React from 'react'
import * as API from './service'
import {isException} from './utils/utilty'
import {isEmpty} from 'lodash'
// import VConsole from 'vconsole'

import ServiceVendor from './component/ServiceVendor/ServiceVendor'
import StationInfo from './component/StationInfo/StationInfo'
// import ScaleWeight from './component/ScaleWeight/ScaleWeight'
import ScaleQRCode from './component/ScaleQRCode/ScaleQRCode'
import UserInfo from './component/UserInfo/UserInfo'
import Datetime from './component/Datetime/Datetime'
import WeightOrderDetail from './component/WeightOrderDetail/WeightOrderDetail'
import './App.css'

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      needLogin:true,
      account: '',
      password: '',
      isLoggedIn: false,
      merchantName: '',
      stationName: '',
      vendorName: '小黄龙环保回收智能供应链系统',
      wsEndPoint: '',
      socketTask:'',
      errMsg:'',
      envData:'',

      owner:{},
      weighter:{},
      connectNetworkTimer: '',
      loginTimer:'',
      isLoginForm: true,

      weight: 0,
      frontCameraImage: '',
      topCameraImage: '',
      backCameraImage: '',
      scaleId: '',
      onlineStatus: '',
      jobOrderId: '',
      grossWeight: 0,
      userType: '',
      tareWeight: 0,
      cardNumber: '',
      isSocketOpen: false

     };
    this.handleAccountChange = this.handleAccountChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getSocketInfo = this.getSocketInfo.bind(this);
    this.createSocket = this.createSocket.bind(this);
    this.closeSocket = this.closeSocket.bind(this);
  }

  componentDidMount () {
    // var vConsole = new VConsole();
    this.startLoginTimer()
  }

  componentWillUnmount(){
    const {socketTask, connectNetworkTimer, loginTimer} = this.setState
    if(!isEmpty(socketTask)){
      this.closeSocket()
    }
    if(!isEmpty(connectNetworkTimer)){
      clearInterval(connectNetworkTimer)
      this.setState({connectNetworkTimer: ''})
    }
    if(!isEmpty(loginTimer)){
      clearInterval(loginTimer)
      this.setState({loginTimer: ''})
    }
  }

  handleChange(e) {
    this.setState({ text: e.target.value });
  }

  handleAccountChange(e) {
    // console.log('handleAccountChange',e.target.value)
    this.setState({account:e.target.value})
  }

  handlePasswordChange(e) {
    console.log('handlePasswordChange',e.target.value)
    this.setState({password:e.target.value})
  }

  handleSubmit(e) {
    e.preventDefault();
    const {account, password} = this.state
    if(!account.length){
      alert('请填写登录账户')
      this.setState({
        isLoginForm: true,
      })
      return
    }
    if(!password.length){
      alert('请填写登录密码')
      this.setState({
        isLoginForm: true,
      })
      return
    }
    this.login({account,password})
  }

  startLoginTimer = () =>{
    const timer = setInterval(() => {
      const {needLogin, isLoggedIn, isSocketOpen, account, password,isLoginForm} = this.state
      const savedAccount = localStorage.getItem('account')||''
      const savedPassword = localStorage.getItem('password')||''
      if(isLoginForm){
        if(!isEmpty(account)||!isEmpty(password)){
          this.login({account, password})
        }else if(!isEmpty(savedAccount)||!isEmpty(savedPassword)){
          this.login({
            account:savedAccount,
            password:savedPassword
          })
        }
        return
      }
      if(needLogin||!isLoggedIn||!isSocketOpen){
        if(!isEmpty(account)||!isEmpty(password)){
          this.login({account, password})
        }else if(!isEmpty(savedAccount)||!isEmpty(savedPassword)){
          this.login({
            account:savedAccount,
            password:savedPassword
          })
        }else{
          this.setState({isLoginForm: true})
        }
      }
    },15*1000)
    this.setState({loginTimer: timer})
  }

  login = (props) => {
    const {account,password} = props
    API.login({account,password})
      .then( res => {
        const {errMsg, isError} = isException(res)
        // const {headers} = res
        // alert(JSON.stringify(headers))
        if(isError){
          const {socketTask} = this.state
          if(!isEmpty(socketTask)){
            socketTask.close()
          }
          this.setState({
            needLogin: true,
            isLoginForm: true,
            isLoggedIn: false,
            isSocketOpen: false,
            socketTask: '',
            errMsg
          })
          // alert(JSON.stringify(res))
          return
        }


        localStorage.setItem('account', account)
        localStorage.setItem('password', password)

        const {data={}} = res
        const {scaleLocation={}, platform={}} = data
        const {displayName:stationName='', merchant={}} = scaleLocation
        const {displayName:merchantName=''} = merchant
        const {displayName:vendorName=''} = platform
        this.setState({
          needLogin: false,
          isLoggedIn: true,
          isLoginForm: false,
          stationName,
          merchantName,
          vendorName
        })
        // this.connectNetwork()
        this.getSocketInfo()
      })
      .catch(err=>{ console.log('err',err) })
  }

  getSocketInfo = () => {
    API.getSocketInfo()
      .then(res => {
        const {errMsg, isError} = isException(res)
        // const {headers} = res
        // alert(JSON.stringify(headers))
        if(isError){
          this.setState({
            needLogin: true,
            errMsg,
            isLoginForm: true
          })
          return
        }
        const {data={}} = res
        const {scaleId='', wsEndPoint=''} = data
        this.setState({
          scaleId,
          wsEndPoint
        })

        const {socketTask={}} = this.state
        console.log('socketTask',socketTask);
        if(!isEmpty(socketTask)){
          socketTask.close()
        }
        if(wsEndPoint.length>10){
          this.createSocket(wsEndPoint)
        }
      })
      .catch( err => console.log('err',err) )
  }

  createSocket = (wsEndPoint) => {
    const socket = new WebSocket(wsEndPoint)
    // alert(JSON.stringify(socket))
    socket.onclose = (e) => {
      console.log('socket onclose', e)
      this.setState({
        isSocketOpen: false,
        socketTask: ''
      })
    }

    socket.onopen = (e) => {
      console.log('socket onopen', e);
      console.log('socket', socket)
      this.setState({
        isSocketOpen: true,
        socketTask: socket
      })
    }
    socket.onerror = (e) => {
      console.log('socket onerror', e);
      console.log('socket', socket)
      const {socketTask} = this.state
      if(!isEmpty(socketTask)){
        socketTask.close()
        this.setState({
          isSocketOpen: false,
          socketTask: ''
        })
      }
    }

    socket.onmessage = (e) => {
      const data = e.data||{}
      // const {data={}} = e
      // console.log('socket.onmessage',e.data)
      if(data !== 'heartbeat'){
        // console.log('data', data);
        const msg = JSON.parse(data)
        console.log('data after parse', msg)
        const {onlineStatus=''} = msg
        if(isEmpty(onlineStatus)){
          console.log('onlineStatus',onlineStatus)
          this.setState({onlineStatus})
          return
        }

        const {scaleStatus={}, weight=0, frontCameraImage='', topCameraImage='', backCameraImage='', scaleId=''} = msg
        console.log('weight',weight)
        const {jobOrderId='', grossWeight='', userType='', tareWeight='', cardNumber=''} = scaleStatus
        this.setState({
          weight,
          frontCameraImage,
          topCameraImage,
          backCameraImage,
          scaleId,
          onlineStatus,
          jobOrderId,
          grossWeight,
          userType,
          tareWeight,
          cardNumber,
          isSocketOpen: true
        })
      }else{
        console.log('心跳', data)
      }
    }
  }

  closeSocket = () =>{
    const {socketTask} = this.state
    console.log('socketTask', socketTask)
    if(!isEmpty(socketTask)){
      socketTask.close()
    }
  }

  connectNetwork = () => {
    const timer = setInterval(()=>{
      const {isSocketOpen} = this.state
      if(!isSocketOpen){
        this.getSocketInfo()
      }
    },30*1000)
    this.setState({connectNetworkTimer: timer})
  }


  render() {
    const {
      // needLogin,
      vendorName='',
      merchantName='',
      stationName='',
      // owner={},
      // weighter={},
      errMsg,
      isSocketOpen,
      isLoginForm,
      orderId,

      weight,
      frontCameraImage,
      topCameraImage,
      backCameraImage,
      scaleId,
      onlineStatus,
      jobOrderId,
      grossWeight,
      userType,
      tareWeight,
      cardNumber,

    } = this.state

    console.log('this.state',this.state)


    const indicatorColor = onlineStatus === 'connected' ? '#04ec00' : 'red';

    return (
      <div className='page'>
        <ServiceVendor vendorName={vendorName} />
        <div className='top-section'>
          {
            isLoginForm
              ?
              <div className='login-container'>
                <form className='login-form' onSubmit={this.handleSubmit}>
                  <h2>小黄龙回收称重显示登录系统</h2>
                  {
                    !isEmpty(errMsg) &&
                    <div className='err-msg'>{errMsg}</div>
                  }
                  <div className='form-item'>
                    <div className='form-label'>账户</div>
                    <div className='input-container'>
                      <input
                        name='account'
                        type='text'
                        onChange={this.handleAccountChange}
                        className='input'
                      />
                    </div>
                  </div>
                  <div className='form-item'>
                    <div className='form-label'>密码</div>
                    <div className='input-container'>
                      <input
                        name='password'
                        type='password'
                        onChange={this.handlePasswordChange}
                        className='input'
                      />
                    </div>
                  </div>
                  <div className='actions'>
                    <button type='submit' className='btn-submit'>登录</button>
                  </div>
                </form>
              </div>
              :
              <div className='home-container'>
                <div className='home-content'>
                  <div className='station-info'>
                    <StationInfo merchant={merchantName} warehouse={stationName} />
                    <div className='weight-container'>
                      <div className='weight-label-container'>
                        <div className='scale-indicator' style={{ backgroundColor: indicatorColor }}></div>
                        <div className='iccard-label'>
                          当前重量
                        </div>
                      </div>
                      <div className='weight-content'>
                        <span className='weight'>{weight}</span>
                        <span className='unit'>Kg</span>
                      </div>
                      {/* {!isEmpty(owner)&&<UserInfo label='当前货主' name={owner.name} />} */}
                      {/* {!isEmpty(weighter)&&<UserInfo label='当前称重人' name={weighter.name} />} */}
                    </div>
                  </div>
                  {!isSocketOpen && <div className='lose-network'>服务失去连接，正在重连</div>}
                  {userType !== 'iccard' && scaleId.length > 0 && <ScaleQRCode scaleId={scaleId} onlineStatus={onlineStatus} />}
                  {userType === 'iccard' && <WeightOrderDetail {...this.state} />}
                </div>

              </div>
          }
          </div>
          <Datetime />
        </div>
    )
  }

}


export default Home

import React from 'react'
import QRCode from 'qrcode-react'
import logo from '../../assets/logo.png'

import './ScaleQRCode.css'

const ScaleQRCode = ({scaleId, onlineStatus}) => {
  const scaleUrl = `https://wxapp.xhl365.cn/jingou/wxappService/customerScaleScanned/${scaleId}/`
  // let boxStatus = ''
  // if(onlineStatus ==='connected'){
  //   boxStatus = ''
  // }
  console.log('ScaleQRCode', onlineStatus)
  return(
    <div className='qrcode-container'>
      {
        onlineStatus === 'connected'
        ?
        (
          <div className='box-connected'>
          <div className='qrcode-label'>请扫码称重</div>
            <QRCode
              size={200}
              value={scaleUrl}
              logo={logo}
              logoWidth={80}
              logoHeight={80}
            />
          </div>
        )
        :
        (
          <div className='box-offline'>
            小盒子断线....请检查线路连接状态
          </div>
        )

      }

    </div>

  )
}

export default ScaleQRCode

import React, { Component } from 'react'
import moment from 'moment-mini'
import {isEmpty} from 'lodash'
import './Datetime.css'

export class Datetime extends Component {

  constructor(props){
    super(props)
    this.state = {
      datetime: moment(new Date()).utcOffset(8).format('YYYY-MM-DD HH:mm:ss'),
      timer: ''
    }
  }

  componentDidMount(){
    const {timer} = this.state
    if(isEmpty(timer)){
      this.startTimer()
    }
  }

  componentWillUnmount(){
    const {timer} = this.state
    if(!isEmpty(timer)){
      this.clearTimer()
    }
  }

  startTimer = () => {
    const timer = setInterval(()=>{
      this.setState({
        datetime: moment(new Date()).utcOffset(8).format('YYYY-MM-DD HH:mm:ss')
      })
    },1000)
    this.setState({timer})
  }

  clearTimer = () => {
    const {timer} = this.state
    if(timer){
      clearInterval(timer)
      this.setState({timer: ''})
    }
  }

  render() {
    const {datetime} = this.state
    return (
      <div className='datetime'>
        {datetime}
      </div>
    )
  }
}

export default Datetime

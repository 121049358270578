import React from 'react'
import {isEmpty} from 'lodash'
import './WeightOrderDetail.css'

const WeightOrderDetail = (params) => {
  const {jobOrderId='',cardNumber='', grossWeight=0, tareWeight=0, frontCameraImage='', topCameraImage='', backCameraImage='', jobOrderStatus=''  } = params
  return (
    <div className='order-detail-container'>
      <div className='weight-label-container'>
        <div className='iccard-label'>
          {`当前单号: ${jobOrderId}  /  IC卡号: ${cardNumber}`}
        </div>
      </div>
      <div className='weight-detail-container'>
        <div className='iccard-container'>
          {
            grossWeight>0 &&
            <div className='card-container'>
              <div className='weight-label'>毛重</div>
              <div className='value-container'>
                <span className='weight-value'>{grossWeight}</span>
                <span className='weight-unit'>Kg</span>
              </div>
            </div>
          }
          {
            jobOrderStatus === 'NeedTareWeight'&&
            <div className='weigh-tare-tips'>请卸货称皮重</div>
          }
          {
            tareWeight > 0 &&
            <div className='card-container'>
              <div className='weight-label'>皮重</div>
              <div className='value-container'>
                <span className='weight-value'>{tareWeight}</span>
                <span className='weight-unit'>Kg</span>
              </div>
            </div>
          }
          {
            (grossWeight > 0 && tareWeight > 0)&&
            <div className='pricing-tips'>请通知站长定价</div>
          }
        </div>
        <div className='weight-image-container'>
          {
            frontCameraImage &&
            <img src={frontCameraImage} width={200} height={200} alt='前方摄像头'/>
          }
        </div>
      </div>
    </div>
  )
}



export default WeightOrderDetail

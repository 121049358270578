import axios from 'axios'
import qs from 'qs'
// import { notification } from 'antd';
// import router from 'umi/router';
// import router from 'dva/router'

axios.defaults.baseURL = 'https://wxapp.xhl365.cn/jingou/'
// axios.defaults.baseURL = 'https://wxapp1.xhl365.cn/jingou/'
axios.defaults.timeout = 10*1000;
axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';



axios.interceptors.response.use((req) => {
  // console.log('RAW req', req)

  // let header = {}
  let AuthorizationCode = sessionStorage.getItem('Authorization')||''
  const {method='GET'} = req


  if(method.toUpperCase() === 'POST'){
    req.data = qs.stringify(req.data)
    req.header = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': AuthorizationCode
    }
  }else{
    req.header = {
      'Content-Type': 'application/json',
      'Authorization': AuthorizationCode
    }
  }


  // console.log('................................');
  // console.log('Send REQUEST                   :', new Date());
  // console.log('req                            :', req);
  // console.log('...............................');
  return req;
},
err =>{
  console.log(err)
  return Promise.reject(err)
})

axios.interceptors.response.use((res) => {
  const {statusCode, header} = res
  if(statusCode >= 200 && statusCode < 300){
    const xClass = header['X-Class']||header['x-class']||''
    if(xClass.endsWith('BusinessLogonPage')){
      //
      // router.push('/login')
      // redirect to login pageya
      return
    }
    // const incomingAuthorizationCode = header['Authorization']||header['authorization']||''
    // const currentAuthorizationCode = sessionStorage.getItem('Authorization')
    // if(incomingAuthorizationCode === ''){
    //   alert('incomingAuthorizationCode is empty')
    // }else{
    //   if(incomingAuthorizationCode !== currentAuthorizationCode){
    //     sessionStorage.setItem('Authorization',incomingAuthorizationCode)
    //   }
    // }
  }


  // const {data} = res
  // const {confi}
  // console.log('***************************');
  // console.log('GET RESPONSE               :', new Date());
  console.log('response:                  :', res);
  // console.log('data:                      :', data);
  // console.log('***************************');
  return res;

})

export default function request(options){
  const {url, data, method='get', ...rest} = options
  console.log('Request URL:', url);
  switch(method.toUpperCase()){
    case 'GET':
      return axios.get(url, {...rest});
    case 'POST':
      return axios.post(url, {...rest});
    default:
      return axios({options})
  }
}

import request from '../utils/request'

// https://wxapp.xhl365.cn/jingou/secUserManager/login/:userid/:password/
export const login = (params) => {
  const {account:userId, password} = params
  // console.log('params', params)
  return request({
    url: `secUserManager/login/${userId}/${password}/`,
    method: 'GET'
  })
}

export const getSocketInfo = () => {
  return request({
    url: 'wxappService/openSocket/',
    method: 'GET'
  })
}

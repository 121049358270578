import React from 'react'
import './ServiceVendor.css'

const ServiceVendor = ({vendorName}) => {
  return(
    <div className='vendor'>
      {vendorName}
    </div>
  )
}
export default ServiceVendor

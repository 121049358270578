export const isException = (res) => {
  const {headers, data} = res
  console.log('res',res);
  const xClass = headers['X-Class']||headers['x-class']||''
  console.log('xClass', xClass);
  if(xClass.endsWith('Exception')){
    const {message=''} = data
    return {
      errMsg: message,
      isError: true
    }
    // alert(message)
    // return true
  }else if(xClass.endsWith('Form')){

    const {errorMessage=''} = data
    // alert(errorMessage)
    
    return {
      errMsg: errorMessage,
      isError: true
    }
  }
    return {
      errMsg: '',
      isError: false
    }
}